import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const locale = window.location.pathname.startsWith("/ar") ? "ar" : "en";

export const i18n = new VueI18n({
  locale: locale, // set initial locale
  fallbackLocale: "en",
  messages: {} // set locale messages
});
